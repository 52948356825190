<template>
  <router-view />
</template>

<style>
a,
router-link {
  text-decoration: none;
}
</style>
